// ************************************************************************************************************************************ //
//  MODALS
// ************************************************************************************************************************************ //


/**
 * Show a modal with given and content
 *
 * @param  {String}  title   modal title
 * @param  {String}  content modal content
 * @param  {Boolean} open    open modal

 */
function showModal(title, content, open = true, bindAjaxAbort = false) {
    if ($('#divModalBootstrap').length > 0) {
        $('#divModalBootstrapContent').html(content);
        $('#divModalBootstrapTitle').html(title);
        if (open) {
            $('#divModalBootstrap').modal('show');
        }

        if (bindAjaxAbort) {
            $('#divModalBootstrap button.close').off();
            $('#divModalBootstrap button.close').on('click', function(){
                if(bindAjaxAbort && bindAjaxAbort.readyState != 4) {
                    bindAjaxAbort.abort();
                }
            });
        }
    }
}

/**
 * Load a page into the modal
 *
 * @param  {String}  title  modal title
 * @param  {String}  source source page
 * @param  {Boolean} open   open modal
 *
 */
function loadModal(title, source, open = true) {
    if ($('#divModalBootstrap').length > 0) {
        $('#divModalBootstrapContent').load(source, '', function(){
            $('#divModalBootstrapTitle').html(title);
            if (open) {
                $('#divModalBootstrap').modal('show');
            }
        });
    }
}

/**
 * Close modal
 *
 */
function jsFecharConfirm() {
    $('#divModalConfirmBootstrap').modal('hide');
}

/**
 * Show confirmation modal
 * @param  {[type]} sTexto modal text
 * @param  {[type]} sURL   content URL to load
 *
 */
function jsConfirm(sTexto,sURL) {
    $('#divModalConfirmBootstrapContent').html(sTexto+"<div id='divButtons' style='text-align:center;margin-top: 20px;'><button type=\"button\" class=\"btn btn-default\" onClick=\"jsFecharConfirm();\" data-dismiss=\"modal\" aria-label=\"Cancelar\">Cancelar</button> <button id='botaoConfirmar' class=\"btn btn-default\" onClick=\"$('#divPesquisa').load('"+sURL+"'); jsFecharConfirm();\">Confirmar</button></div>");
    $('#divModalConfirmBootstrap').modal('show');
}

// trufas
if($('#modal-truffas').length) {
  $('#carousel-trufes-mobile .item a').click(function(e){
    e.preventDefault();

    var self = $(this),
        index = self.data('index');

    $('#modal-truffas').modal('show');
    $('#carrossel-truffes-description').carousel(index-1);
  });

  $('#carrossel-truffes-description').on('slid.bs.carousel', function (event) {
    var index = $('#modal-truffas .item.active').index() + 1
        number = $('#modal-truffas .number p.active');

    number.text(index);
  });
}